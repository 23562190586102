import React from 'react';
import {graphql} from 'gatsby';

import SharedStyles from './shared-styles.js';
import {extractHeader} from './util.js';

export default ({data}) => {
    const post = data.allMarkdownRemark.edges[0].node;
    const [body, headerHref] = extractHeader(post.html);

    return <div style={SharedStyles.container}>
        <header style={styles.header}>
            <img src={headerHref} style={styles.headerImg} />
        </header>
        <article style={SharedStyles.article(true)}>
            <h1 style={SharedStyles.h1}>{post.frontmatter.title}</h1>
            <h2 style={SharedStyles.h2}>{post.frontmatter.dateline}</h2>
            <div style={styles.postContents} dangerouslySetInnerHTML={{ __html: body }} />
        </article>

        <article style={SharedStyles.article(false)}>
            <h1 style={SharedStyles.h1}>Arguing with Algorithms</h1>
            <p style={SharedStyles.p}>See the <a href="/blog" style={SharedStyles.a}>full archive</a>.</p>
        </article>

        <footer style={SharedStyles.footer}>
            ~ &copy; 2019 Tom Yedwab ~
        </footer>
    </div>;
};

const styles = {
    header: {
        position: "absolute",
        width: "100%",
        height: 276,
        overflowY: "hidden",
    },

    headerImg: {
        width: "100%",
    },

    postContents: {
        backgroundColor: "#f8f4e3",
        width: 300,
        margin: "0 auto",

        width: 600,
        margin: "36px auto",
        color: "#000",
        padding: "12px 68px",
        lineHeight: "34px",
        fontSize: "20px",
        textAlign: "justify",
    },
}

export const query = graphql`
query($postId: String!) {
    allMarkdownRemark(
        filter: {
            id: {eq: $postId}
        }
    ) {
        edges {
            node {
                fileAbsolutePath
                frontmatter {
                    title
                    dateline
                }
                excerpt
                html
            }
        }
    }
}`;